import { connectRefinementList } from 'instantsearch.js/es/connectors';
import {
  $,
  $$,
  append,
  fragment,
  html,
  noop,
  on,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';
import { getContrastColor, getColorBackground, parseItems } from '../../utils/algolia/color-refinement-helper';
import '../../../scss/components/algolia/color-refinement-list.scss';

export default {
  args: 'attribute',

  props: {
    attribute: String,
    noItemsLabel: String,
  },

  data: {
    noItemsLabel: 'No filter items available',
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        if (store.state.disjunctiveFacets.includes(this.attribute)) {
          return;
        }

        store.addWidget(
          this.renderRefinementList()({
            container: this.$el,
            attribute: this.attribute,
            limit: 99,
            separator: ';',
            operator: 'or',
            sortBy: ['name:asc'],
          }),
        );
      });
  },

  methods: {
    renderRefinementList() {
      const render = (renderOptions, isFirstRender) => {
        const {
          items,
          refine,
          widgetParams: { container, separator },
        } = renderOptions;
        if (isFirstRender) {
          const wrapper = fragment(`
            <div class="ais-ColorRefinementList ais-ColorRefinementList-layoutGrid ais-ColorRefinementList-shapeCircle">
              <div class="ais-ColorRefinementList-items" role="group" aria-label="Colors"></div>
            </div>
          `);

          append(container, wrapper);
        }

        const resultItems = parseItems(items, separator);

        if (resultItems.length > 0) {
          html($('div.ais-ColorRefinementList-items', container), resultItems.map((item) => `
            <button
              type="button"
              class="ais-ColorRefinementList-item ais-ColorRefinementList${item.isRefined ? '-itemRefined' : ''}"
              role="menuitemcheckbox"
              aria-checked="${item.isRefined}"
              aria-label="Refine on ${item.label}"
              data-value="${item.value}"
            >
              <div class="ais-ColorRefinementList-color${item.hex ? ` color--${item.hex.toLowerCase().substring(1)}` : ''}" style="${getColorBackground(item)}">
                <div class="ais-ColorRefinementList-refinedIcon" style="--contrast-color: ${item.rgba ? getContrastColor(item.rgba) : '#ffffff'};">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                  </svg>
                </div>
              </div>
              <div class="ais-ColorRefinementList-label">${item.label}</div>
              <div class="ais-ColorRefinementList-count ais-RefinementList-count">${item.count}</div>
            </button>
          `).join(''));

          on($$('button', container), 'click', ({ currentTarget }) => {
            refine(currentTarget.dataset.value);
          });
        } else {
          html($('div.ais-ColorRefinementList-items', container), `<li>${this.noItemsLabel}</li>`);
        }
      };

      return connectRefinementList(render, noop);
    },
  },
};
