import { stats } from 'instantsearch.js/es/widgets';
import SearchStore from '../../search-store';

export default {
  props: {
    noResults: String,
    oneResult: String,
    manyResults: String,
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        store.addWidget(
          stats({
            container: this.$el,
            cssClasses: { text: ['uk-text-medium'] },
            templates: {
              text: (data, { html }) => html`
                ${data.hasNoResults ? this.noResults : ''}
                ${data.hasOneResult ? this.oneResult : ''}
                ${data.hasManyResults ? `${data.nbHits} ${this.manyResults}` : ''}
              `,
            },
          }),
        );
      });
  },
};
