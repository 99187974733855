import { $ } from 'uikit/src/js/util';

export default {
  props: {
    path: String,
    logout: String,
  },
  data: {
    btnConfirm: '#btn-confirm',
    path: '',
    logout: '',
  },
  computed: {
    confirm() {
      return $(this.btnConfirm, this.$el);
    },
  },
  events: [
    {
      name: 'click',

      el() {
        return this.confirm;
      },

      handler() {
        fetch(this.path, {
          method: 'get',
        })
          .then((response) => response.json())
          .then(() => {
            window.location.replace('/');
          })
          .catch((err) => {
            console.log('FOO');
            console.log(err);
          });
      },
    },
  ],
};
