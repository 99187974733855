import { query, $ } from 'uikit/src/js/util';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import prettyBytes from 'prettier-bytes';

const German = require('@uppy/locales/lib/de_DE');
const French = require('@uppy/locales/lib/fr_FR');
const Spanish = require('@uppy/locales/lib/es_ES');
const English = require('@uppy/locales/lib/en_US');

export default {
  args: 'target',

  props: {
    endpoint: String,
    fileSize: Number,
    fileTypes: 'list',
    numberOfFiles: Number,
    target: String,
    uploadStrategy: String,
    hiddenFileIds: String,
    locale: String,
  },

  data: {
    endpoint: '/ajax/upload',
    fileSize: 1000000,
    fileTypes: ['image/*'],
    numberOfFiles: 3,
    target: '- *',
    uploadStrategy: 'default',
    locale: 'de',
  },

  computed: {
    target({ target }, $el) {
      return query(target, $el) || $el;
    },

    uppyLocale() {
      if (this.locale === 'es') {
        return Spanish;
      }

      if (this.locale === 'fr') {
        return French;
      }

      if (this.locale === 'en') {
        return English;
      }

      return German;
    },
  },

  connected() {
    this.uppy = Uppy({
      autoProceed: true,
      meta: { strategy: this.uploadStrategy },
      restrictions: {
        maxFileSize: this.fileSize,
        maxNumberOfFiles: this.numberOfFiles,
        allowedFileTypes: this.fileTypes,
      },
      locale: this.uppyLocale,
    }).use(Dashboard, {
      inline: true,
      width: '100%',
      height: 300,
      target: this.target,
      replaceTargetContent: true,
      showProgressDetails: true,
      showRemoveButtonAfterComplete: true,
      hideUploadButton: false,
      doneButtonHandler: null,
      disableStatusBar: false,
      disableInformer: false,
      note: `Max. ${this.numberOfFiles} Dateien, max. ${prettyBytes(this.fileSize)} pro Datei`,
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'Dateiname' },
      ],
      locale: this.uppyLocale,
      proudlyDisplayPoweredByUppy: false,
    }).use(XHRUpload, {
      endpoint: this.endpoint,
      fieldName: 'uploaded_file',
      metaFields: ['name', 'strategy'],
      locale: this.uppyLocale,
    });

    this.uppy.on('complete', (result) => {
      if (result.successful.length > 0) {
        const assets = result.successful.map((file) => file.response.body.asset);
        if (this.$el.value) {
          assets.unshift(this.$el.value);
        }
        this.$el.value = assets.join(',');
        if ($(this.hiddenFileIds, this.document).value) {
          $(this.hiddenFileIds, this.document).value = `${$(this.hiddenFileIds, this.document).value},${assets.join(',')}`;
        } else {
          $(this.hiddenFileIds, this.document).value = assets.join(',');
        }
      }

      if (result.failed.length > 0) {
        const fileNames = result.failed.map((file) => file.name).join(', ');
        this.uppy.info({
          message: 'Fehler: Upload einiger Dateien fehlgeschlagen!',
          details: `Dateien: ${fileNames}`,
        }, 'error', 5000);
      }
    });
  },

  events: [
    {
      name: 'reset',

      el() {
        return $(this.$el, 'form');
      },

      handler() {
        this.uppy.upload();
        this.uppy.reset();
      },
    },
  ],
};
