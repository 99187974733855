import { connectRefinementList } from 'instantsearch.js/es/connectors';
import {
  $,
  $$,
  append,
  fragment,
  html,
  noop,
  on,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';

export default {
  args: 'attribute',

  props: {
    attribute: String,
    noItemsLabel: String,
    showLessLabel: String,
    showMoreLabel: String,
  },

  data: {
    noItemsLabel: 'No filter items available',
    showLessLabel: 'Show less',
    showMoreLabel: 'Show more',
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        if (store.state.disjunctiveFacets.includes(this.attribute)) {
          return;
        }

        store.addWidget(
          this.renderRefinementList()({
            container: this.$el,
            attribute: this.attribute,
            operator: 'or',
            sortBy: ['name:asc'],
            showMore: true,
            showMoreLimit: 50,
          }),
        );
      });
  },

  methods: {
    renderRefinementList() {
      const render = (renderOptions, isFirstRender) => {
        const {
          canToggleShowMore,
          isShowingMore,
          items,
          refine,
          toggleShowMore,
          widgetParams: { container },
        } = renderOptions;
        if (isFirstRender) {
          const ul = fragment('<ul class="uk-list uk-list-collapse uk-margin-remove"></ul>');
          const button = fragment(`<button class="uk-button uk-button-default uk-button-small uk-margin-top">${this.showMoreLabel}</button>`);

          on(button, 'click', () => {
            toggleShowMore();
          });

          append(container, ul);
          append(container, button);
        }

        if (items.length > 0) {
          html($('ul', container), items.map((item) => `
            <li>
              <label class="uk-cursor-pointer ${item.isRefined ? 'uk-text-bold' : ''}">
                <input class="uk-checkbox" type="checkbox" data-value="${item.value}" ${item.isRefined ? 'checked' : ''}>
                ${item.label} <span class="uk-badge uk-badge-muted">${item.count}</span>
              </label>
            </li>
          `).join(''));

          on($$('input[type="checkbox"]', container), 'change', ({ currentTarget }) => {
            refine(currentTarget.dataset.value);
          });
        } else {
          html($('ul', container), `<li>${this.noItemsLabel}</li>`);
        }

        const button = $('button', container);

        button.hidden = !canToggleShowMore;
        button.textContent = isShowingMore ? this.showLessLabel : this.showMoreLabel;
      };

      return connectRefinementList(render, noop);
    },
  },
};
