import * as Sqrl from 'squirrelly';
import { highlight } from 'instantsearch.js/es/helpers';
import { connectHits } from 'instantsearch.js/es/connectors';
import { $, html, noop } from 'uikit/src/js/util';
import SearchStore from '../../search-store';

Sqrl.defaultConfig.tags = ['{[', ']}'];
Sqrl.helpers.define('highlight', ({ params }) => highlight(params[0]));

export default {
  computed: {
    templateHitItem: {
      get(data, $el) {
        return $('#hit-template', $el).innerHTML || '';
      },
    },

    templateNoResults: {
      get(data, $el) {
        return $('#no-results-template', $el).innerHTML || '';
      },
    },

    hitsContainer: {
      get(data, $el) {
        return $('#js-product-list', $el);
      },
    },
  },

  connected() {
    SearchStore.instance.initialize()
      .then((search) => {
        search.addWidget(
          this.renderHits()({ container: this.$el }),
        );
      });
  },

  methods: {
    renderHits() {
      const render = (renderOptions, isFirstRender) => {
        const {
          hits,
          results,
        } = renderOptions;
        if (isFirstRender) {
          return;
        }

        let content;

        if (results.nbHits > 0) {
          content = hits.map((item) => Sqrl.render(this.templateHitItem, item)).join('');
        } else {
          content = Sqrl.render(this.templateNoResults, {});
        }

        html(this.hitsContainer, content);
      };

      return connectHits(render, noop);
    },
  },
};
