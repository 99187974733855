import {
  $,
  attr,
  data,
  empty,
  removeAttr,
  trigger,
} from 'uikit/src/js/util';

export default {
  computed: {
    invoiceAddress() {
      return $('select[name="coreshop[invoiceAddress]"]', this.$el);
    },

    invoicePanel() {
      return $('.js-panel-invoice-address', this.$el);
    },

    shippingAddAddressButton() {
      return $('.js-add-shipping-address', this.$el);
    },

    shippingAddress() {
      return $('select[name="coreshop[shippingAddress]"]', this.$el);
    },

    shippingField() {
      return $('.js-shipping-address-selector', this.$el);
    },

    shippingPanel() {
      return $('.js-panel-shipping-address', this.$el);
    },

    useIasS() {
      return $('input[name="coreshop[useInvoiceAsShipping]"]', this.$el);
    },
  },

  connected() {
    if (!this.useIasS.checked && this.shippingAddAddressButton) {
      removeAttr(this.shippingAddAddressButton, 'hidden');
    }

    if (this.invoiceAddress.value) {
      this.handleInvoiceAddress();
    }

    if (this.shippingAddress.value) {
      const [address] = this.getAddress(this.shippingAddress);

      if (address) {
        this.shippingPanel.innerHTML = address.html;
      }
    }
  },

  events: [
    {
      name: 'change',

      el() {
        return this.invoiceAddress;
      },

      handler({ currentTarget }) {
        const address = this.handleInvoiceAddress();

        if (address) {
          if (!this.useIasS.checked) {
            return;
          }

          this.shippingAddress.value = currentTarget.value;
          trigger(this.shippingAddress, 'change');
        } else {
          empty(this.invoicePanel);

          if (this.useIasS.checked) {
            empty(this.shippingPanel);
            this.shippingAddress.value = null;
            trigger(this.shippingAddress, 'change');
          }
        }
      },
    },

    {
      name: 'change',

      el() {
        return this.shippingAddress;
      },

      handler({ currentTarget }) {
        const [address] = this.getAddress(currentTarget);

        if (address) {
          this.shippingPanel.innerHTML = address.html;
        } else {
          empty(this.shippingPanel);
        }
      },
    },

    {
      name: 'change',

      el() {
        return this.useIasS;
      },

      handler({ currentTarget }) {
        if (currentTarget.checked) {
          attr(this.shippingField, 'hidden', 'hidden');

          const selectedAddress = this.invoiceAddress.options[this.invoiceAddress.selectedIndex];
          const invoiceAddress = data(selectedAddress, 'address');

          if (invoiceAddress) {
            this.shippingAddress.value = selectedAddress.value;
            trigger(this.shippingAddress, 'change');
          }

          if (this.shippingAddAddressButton) {
            attr(this.shippingAddAddressButton, 'hidden', 'hidden');
          }
        } else {
          removeAttr(this.shippingField, 'hidden');

          if (this.shippingAddAddressButton) {
            removeAttr(this.shippingAddAddressButton, 'hidden');
          }
        }
      },
    },
  ],

  methods: {
    getAddress(select) {
      const selected = select.options[select.selectedIndex];
      let address = data(selected, 'address');

      if (address) {
        address = JSON.parse(address);
      }

      return [address, selected];
    },

    handleInvoiceAddress() {
      const [address, selected] = this.getAddress(this.invoiceAddress);
      const addressType = data(selected, 'address-type');

      if (this.useIasS) {
        if (addressType === 'invoice') {
          this.useIasS.disabled = true;
          this.useIasS.checked = false;
          trigger(this.useIasS, 'change');
        } else {
          this.useIasS.disabled = false;
        }
      }

      if (address) {
        this.invoicePanel.innerHTML = address.html;
      }

      return address;
    },
  },
};
