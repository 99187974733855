/* eslint-disable camelcase, max-len */

import instantsearch from 'instantsearch.js';
import algoliasearch from 'algoliasearch/lite';

class SearchStore {
  constructor(applicationId, apiKey, indexName, locale) {
    this.applicationId = applicationId;
    this.apiKey = apiKey;
    this.indexName = indexName;
    this.locale = locale;
  }

  get state() {
    return this.search.helper.state;
  }

  addWidget(widget) {
    this.search.addWidgets([widget]);

    if (!this.search.started) {
      this.search.start();
    }
  }

  initialize() {
    if (!SearchStore.instance) {
      const searchClient = algoliasearch(this.applicationId, this.apiKey);

      this.search = instantsearch({
        indexName: this.indexName,
        searchClient,
        routing: {
          stateMapping: {
            stateToRoute: ({
              [this.indexName]: {
                page,
                query,
                range,
                refinementList,
                sortBy,
              },
            }) => ({
              brands: refinementList?.[`brand_${this.locale}`],
              category: refinementList?.[`main_category_${this.locale}`],
              colors: refinementList?.[`color_${this.locale}`],
              content: range?.content,
              volume: range?.volume,
              price: range?.[`price_${this.locale}.amount`],
              sizes: refinementList?.[`size_${this.locale}`],
              features: refinementList?.[`features_${this.locale}`],
              page,
              query,
              sort: sortBy && sortBy.substr(sortBy.indexOf(`${this.indexName}`) + this.indexName.length + 1),
            }),
            routeToState: ({
              page,
              category,
              brands,
              content,
              colors,
              features,
              price,
              sizes,
              sort,
              query,
              volume,
            }) => ({
              [this.indexName]: {
                page,
                query,
                range: {
                  [`price_${this.locale}.amount`]: price,
                  content,
                  volume,
                },
                refinementList: {
                  [`main_category_${this.locale}`]: category,
                  [`brand_${this.locale}`]: brands,
                  [`color_${this.locale}`]: colors,
                  [`size_${this.locale}`]: sizes,
                  [`features_${this.locale}`]: features,
                },
                sortBy: sort && `${this.indexName}_${sort}`,
              },
            }),
          },
        },
        searchFunction(helper) {
          helper.search();
        },
      });

      SearchStore.instance = this;
    }

    return Promise.resolve(SearchStore.instance);
  }
}

export default SearchStore;
