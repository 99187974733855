import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import {
  $$,
  html,
  noop,
  on,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';

export default {
  props: {
    locale: String,
    brandLabel: String,
    categoryLabel: String,
    colorLabel: String,
    priceLabel: String,
    sizeLabel: String,
    contentLabel: String,
    featureLabel: String,
  },

  data: {
    brandLabel: 'Brand',
    categoryLabel: 'Category',
    colorLabel: 'Color',
    priceLabel: 'Price',
    sizeLabel: 'Size',
    contentLabel: 'Content',
    volumeLabel: 'Volume',
    featureLabel: 'Feature',
  },

  computed: {
    labelMap() {
      return {
        [`brand_${this.locale}`]: this.brandLabel,
        [`main_category_${this.locale}`]: this.categoryLabel,
        [`color_${this.locale}`]: this.colorLabel,
        [`price_${this.locale}.amount`]: this.priceLabel,
        [`size_${this.locale}`]: this.sizeLabel,
        [`features_${this.locale}`]: this.featureLabel,
        content: this.contentLabel,
        volume: this.volumeLabel,
      };
    },
  },

  connected() {
    SearchStore.instance.initialize()
      .then((search) => {
        search.addWidget(this.renderCurrentRefinements()({
          container: this.$el,
          transformItems: (items) => {
            items.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              item.label = this.labelMap[item.attribute];

              if (item.attribute === `color_${this.locale}`) {
                item.refinements.forEach((refinement) => {
                  // eslint-disable-next-line no-param-reassign
                  refinement.label = refinement.label.substring(0, refinement.label.indexOf(';'));
                });
              }
            });

            return items.filter((item) => {
              if (item.attribute !== `categories_${this.locale}.lvl0`) {
                return true;
              }

              return item.refinements.some(({ value }) => (value.match(/>/g) || []).length > 1);
            });
          },
        }));
      });
  },

  methods: {
    renderCurrentRefinements() {
      const createDataAttributes = (refinement) => Object.keys(refinement)
        .map((key) => `data-${key}="${refinement[key]}"`)
        .join(' ');

      // eslint-disable-next-line array-callback-return
      const renderListItem = (item) => item.refinements.map((refinement) => `
        <li>
          <span>
            ${item.label}: <span class="uk-text-bold">${refinement.label}</span>
            <button class="uk-close" ${createDataAttributes(refinement)} data-uk-close="ratio: 0.75"></button>
          </span>
        </li>
      `).join('');

      const render = (renderOptions) => {
        const {
          items,
          refine,
          widgetParams: { container },
        } = renderOptions;

        html(container, `
          <ul class="uk-tags uk-flex-wrap">
            ${items.map(renderListItem).join('')}
          </ul>
        `);

        on($$('button', container), 'click', ({ currentTarget }) => {
          const item = Object.keys(currentTarget.dataset).reduce(
            (acc, key) => ({
              ...acc,
              [key]: currentTarget.dataset[key],
            }),
            {},
          );

          refine(item);
        });
      };

      return connectCurrentRefinements(render, noop);
    },
  },
};
