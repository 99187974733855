/* eslint-disable no-param-reassign */

import {
  $$,
  hasOwn,
  on,
  toNumber,
  trigger,
  ucfirst,
} from 'uikit/src/js/util';

export default class VariantSelector {
  attributeGroups = [];

  constructor(container, config) {
    this.container = container;
    this.config = config;

    this.init();
  }

  clearGroup(group) { // eslint-disable-line class-methods-use-this
    delete group.selected;

    group.elements.forEach((element) => {
      element.disabled = true;
      element.checked = false;

      // remove options on select
      if (element.tagName.toLowerCase() === 'select') {
        const options = $$('option:not([value=""])', element);

        options.forEach((option) => {
          element.removeChild(option);
        });
      }
    });
  }

  clearGroups(group) {
    do {
      this.clearGroup(group);
      group = group.nextGroup;
    } while (group);
  }

  filterAttributes(attributes, group) {
    const filterAttributes = [];

    group = group.prevGroup;

    while (group) {
      if (group.selected && group.nextGroup) {
        filterAttributes.push({ group: group.group.id, selected: group.selected });
      }

      group = group.prevGroup;
    }

    const filtered = [];

    attributes.forEach((attribute) => {
      attribute.products.forEach((product) => {
        if (filterAttributes.every(
          (x) => hasOwn(this.config.index[product.id].attributes, x.group)
            && this.config.index[product.id].attributes[x.group] === x.selected,
        ) && !filtered.includes(attribute)) {
          filtered.push(attribute);
        }
      });
    });

    return filtered.length ? filtered : attributes;
  }

  configureGroup(group) {
    let attributes = group.attributes.slice();
    attributes = this.filterAttributes(attributes, group);

    if (!attributes) {
      return;
    }

    group.elements.forEach((element) => {
      attributes.forEach((attribute) => {
        // set options on select, otherwise only enable inputs
        if (element.tagName.toLowerCase() === 'select') {
          const option = new Option(attribute.attribute.name, attribute.attribute.id);
          option.id = `attribute-${attribute.attribute.id}`;

          if (group.selected === attribute.attribute.id) {
            option.selected = true;
          }

          element.add(option);
          element.disabled = false;
        } else if (toNumber(element.dataset.group) === group.group.id
          && toNumber(element.value) === attribute.attribute.id) {
          element.disabled = false;

          if (group.selected === attribute.attribute.id) {
            element.checked = true;
          }
        }
      });
    });
  }

  setupAttributeGroupSettings() {
    let index = this.attributeGroups.length;

    while (index--) { // eslint-disable-line no-plusplus
      this.attributeGroups[index].prevGroup = this.attributeGroups[index - 1];
      this.attributeGroups[index].nextGroup = this.attributeGroups[index + 1];
    }

    index = this.attributeGroups.length;

    while (index--) { // eslint-disable-line no-plusplus
      if (!index || this.attributeGroups[index].selected) {
        this.configureGroup(this.attributeGroups[index]);
      } else {
        this.clearGroup(this.attributeGroups[index]);
      }
    }
  }

  setupChangeEvents() {
    this.attributeGroups.forEach((group) => {
      group.elements.forEach((element) => {
        on(element, 'change', () => {
          this.configureElement(group, element);
        });
      });
    });
  }

  init() {
    if (!this.container) {
      return;
    }

    this.config.attributes.forEach((group) => {
      group.elements = $$(`[data-group="${group.group.id}"]`, this.container);
      this.attributeGroups.push(group);
    });

    this.setupAttributeGroupSettings();
    this.setupChangeEvents();
  }

  redirectToVariant() {
    const groups = this.attributeGroups.filter((g) => g.selected);
    const selected = Object.fromEntries(groups.map((g) => [g.group.id, g.selected]));

    const filtered = Object.values(this.config.index)
      .filter((p) => JSON.stringify(p.attributes) === JSON.stringify(selected));

    // length should always be 1, but let's check it
    if (filtered.length === 1 && filtered[0].url) {
      window.location.href = filtered[0].url;
    }
  }

  triggerEvent(name, detail = {}) {
    return trigger([this.container], `variantSelector${ucfirst(name)}`, detail);
  }

  configureElement(group, element) {
    this.triggerEvent('change', { element });

    if (element.value) {
      group.selected = toNumber(element.value);

      if (group.nextGroup) {
        this.triggerEvent('select', { element });
        this.clearGroups(group.nextGroup);
        this.configureGroup(group.nextGroup);
      } else {
        this.triggerEvent('redirect', { element });
        this.redirectToVariant();
      }
    } else {
      delete group.selected; // eslint-disable-line no-param-reassign

      if (group.nextGroup) {
        this.clearGroups(group.nextGroup);
      }
    }
  }
}
