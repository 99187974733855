import { connectSortBy } from 'instantsearch.js/es/connectors';
import {
  $$,
  html,
  noop,
  on,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';

export default {
  props: {
    indexName: String,
    defaultSorting: String,
    indices: Object,
  },

  data: {
    defaultSorting: 'relevance',
  },

  connected() {
    console.log(this.indices);
    SearchStore.instance.initialize()
      .then((store) => {
        store.addWidget(
          this.renderSortBy()({
            container: this.$el,
            items: this.indices,
          }),
        );
      });
  },

  methods: {
    renderSortBy() {
      const render = (renderOptions, isFirstRender) => {
        const {
          options,
          currentRefinement,
          refine,
          widgetParams: { container },
        } = renderOptions;

        if (isFirstRender) {
          if (this.defaultSorting !== 'relevance') {
            refine(`${this.indexName}_${this.defaultSorting}`);
          }

          return;
        }

        html(container, options.map((option) => option.html || `
          <li${option.value === currentRefinement ? ' class="uk-active"' : ''}>
            <a href="#" class="uk-link-ignore-base${option.value === currentRefinement ? ' uk-text-medium' : ''}" data-value="${option.value}">  ${option.value === currentRefinement ? '<span data-uk-icon="check" data-ratio=".8"></span>' : ''}${option.label}</a>
          </li>
        `).join(''));

        on($$('a', container), 'click', (event) => {
          event.preventDefault();
          refine(event.currentTarget.dataset.value);
        });
      };

      return connectSortBy(render, noop);
    },
  },
};
