/* eslint-disable */

import SearchStore from '../../search-store';
import { $, $$, append, fragment, html, noop, on, addClass, removeClass } from 'uikit/src/js/util';
import { connectRefinementList } from 'instantsearch.js/es/connectors';

export default {
  args: 'attribute',

  props: {
    attribute: String,
    operator: String,
    limit: Number,
    sortBy: 'list',
    translate: Boolean,
  },

  data: {
    operator: 'or',
    limit: 50,
    sortBy: ['name:asc'],
    translate: true,
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        if (store.state.disjunctiveFacets.includes(this.attribute)) {
          return;
        }

        store.addWidget(
          this.renderRefinementList()({
            container: this.$el,
            attribute: this.attribute,
            operator: this.operator,
            limit: this.limit,
            sortBy: this.sortBy,
            transformItems: (items) => {
              if (this.translate) {
                items.sort((a, b) => {
                  const transLabelA = a.label;
                  const transLabelB = b.label;

                  return (transLabelA > transLabelB) ? 1 : ((transLabelA < transLabelB) ? -1 : 0);
                });
              }

              return items;
            },
          }),
        );
      });
  },

  methods: {
    renderRefinementList() {
      const render = (renderOptions, isFirstRender) => {
        const {
          items,
          refine,
          widgetParams: { container },
        } = renderOptions;

        if (isFirstRender) {
          append(container, fragment('<div class="uk-child-width-1-6@l uk-child-width-1-10@xl uk-child-width-1-5@s uk-child-width-1-4 uk-grid uk-grid-small" data-uk-grid></div>'));
        }

        html($('.uk-grid', container), items.map(({ isRefined, label, value, count }) => {
          const valuesArray = value.split('|:|');

          if (valuesArray[0] === 'Zubehör' || valuesArray[0] === 'Accessoires' || valuesArray[0] === 'Accessori') {
            return;
          }

          if (valuesArray.length > 1) {
            return `
              <div>
                <div class="tm-card-coreshop-category-overview-sub uk-card uk-card-small uk-card-default uk-card-hover"
                   data-uk-tooltip="title: ${valuesArray[0]}; pos: bottom; container: .uk-card-body; offset: 15">
                  <label class="${isRefined ? 'uk-active' : ''} uk-cursor-pointer uk-display-block">
                    <input class="uk-checkbox" type="checkbox" data-value="${value}" ${isRefined ? 'checked' : ''}>
                    <div class="uk-card-media-top uk-overflow-hidden uk-position-relative">
                      <img src="${valuesArray[1]}" alt="">
                    </div>
                    <div class="uk-card-body uk-padding-remove"></div>
                  </label>
                </div>
              </div>
            `;
          }

          return `
            <label class="${isRefined ? 'uk-active' : ''} uk-cursor-pointer uk-display-block uk-height-1-1 uk-width-1-1">
              <input class="uk-checkbox" type="checkbox" data-value="${value}" ${isRefined ? 'checked' : ''}>
              <span class="uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-text-small uk-width-1-1">${value.trim().substring(0,3).toUpperCase()}</span>
              <div class="uk-card-body uk-padding-remove"></div>
            </label>
          `
        }).join(''));

        on($$('input[type="checkbox"]', container), 'change', ({ currentTarget }) => {
          refine(currentTarget.dataset.value);
        });
      };

      return connectRefinementList(render, noop);
    },
  },
};
