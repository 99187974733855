import {
  $, $$,
  data,
} from 'uikit/src/js/util';

export default {
  props: {
    countLabel: String,
    countLabelSingle: String,
  },

  data: {
    countLabel: 'Produkte',
    countLabelSingle: 'Produkt',
  },
  computed: {
    spanCount() {
      return $('.js-comparison-count', this.$el);
    },
    spanCategory() {
      return $('.js-comparison-category', this.$el);
    },
    categorySwitcher() {
      return $('.js-comparison-switcher', this.$el);
    },
    categoriesSwitcher() {
      return $$('.js-comparison-switcher', this.$el);
    },
    comparisonGrid() {
      return $$('.tm-comparison-grid');
    },
  },

  connected() {
    if (this.categorySwitcher) {
      this.changeButton(this.categorySwitcher);
    }
  },

  methods: {
    changeButton(categorySwitcher) {
      const count = data(categorySwitcher, 'data-count');
      this.spanCount.textContent = count > 1 ? `${count} ${this.countLabel}` : `${count} ${this.countLabelSingle}`;
      this.spanCategory.textContent = data(categorySwitcher, 'data-category');
      this.changeComparisonGrid(count);
    },

    changeComparisonGrid(count) {
      this.comparisonGrid.forEach((grid) => {
        // eslint-disable-next-line no-param-reassign
        grid.style.gridTemplateColumns = `repeat(${count}, 400px)`;
      });
    },
  },

  events: [
    {
      name: 'click',

      el() {
        return this.categoriesSwitcher;
      },

      handler({ currentTarget }) {
        this.changeButton(currentTarget);
      },
    },
  ],
};
