import { isNumber } from 'uikit/src/js/util';

export function isFloat(number) {
  return isNumber(number) && number % 1 !== 0;
}

export function isInteger(number) {
  return isNumber(number) && number % 1 === 0;
}

export function round(number, decimals = null) {
  if (!isNumber(number)) {
    return number;
  }

  const rounded = Math.round((number + Number.EPSILON) * 100) / 100;

  return decimals ? rounded.toFixed(decimals) : rounded;
}
