/* eslint-disable */
import polyfills from '@/js/polyfills';

// Import base
import UIkit from './base/uikit';
import '../scss/app.scss';

// Import Components
import AlgoliaClearRefinements from '@/js/components/algolia/clear-refinements';
import AlgoliaCategoryRefinements from '@/js/components/algolia/categories-refinement';
import AlgoliaColorRefinementList from '@/js/components/algolia/color-refinement-list';
import AlgoliaCurrentRefinements from '@/js/components/algolia/current-refinements';
import AlgoliaConnect from '@/js/components/algolia/connect';
import AlgoliaHierarchicalMenu from '@/js/components/algolia/hierarchical-menu';
import AlgoliaHits from '@/js/components/algolia/hits';
import AlgoliaPagination from '@/js/components/algolia/pagination';
import AlgoliaRangeInput from '@/js/components/algolia/range-input';
import AlgoliaRefinementList from '@/js/components/algolia/refinement-list';
import AlgoliaSearchBox from '@/js/components/algolia/search-box';
import AlgoliaSortBy from '@/js/components/algolia/sort-by';
import AlgoliaStats from '@/js/components/algolia/stats';
import AlgoliaRangeSlider from '@/js/components/algolia/range-input-slider';
import Autocomplete from '@/js/components/autocomplete';
import CheckoutAddress from '@/js/components/checkout-address';
import ConsentButton from '@/js/components/consent-button';
import Deactivate from '@/js/components/deactivate-customer';
import FileUpload from '@/js/components/file-upload';
import Form from '@/js/components/form';
import ProductVariants from './components/product-variants';
import Comparison from './components/comparison';

const app = () => {
  // Make UIkit accessible via browser console
  global.UIkit = UIkit;

  // Register Components
  UIkit.component('product-variants', ProductVariants);
  UIkit.component('algolia-connect', AlgoliaConnect); // This needs to be first!
  UIkit.component('algolia-category-refinement-list', AlgoliaCategoryRefinements); // This needs to be first!
  UIkit.component('algolia-clear-refinements', AlgoliaClearRefinements);
  UIkit.component('algolia-color-refinement-list', AlgoliaColorRefinementList);
  UIkit.component('algolia-current-refinements', AlgoliaCurrentRefinements);
  UIkit.component('algolia-hierarchical-menu', AlgoliaHierarchicalMenu);
  UIkit.component('algolia-hits', AlgoliaHits);
  UIkit.component('algolia-pagination', AlgoliaPagination);
  UIkit.component('algolia-range-input', AlgoliaRangeInput);
  UIkit.component('algolia-range-slider', AlgoliaRangeSlider);
  UIkit.component('algolia-refinement-list', AlgoliaRefinementList);
  UIkit.component('algolia-search-box', AlgoliaSearchBox);
  UIkit.component('algolia-sort-by', AlgoliaSortBy);
  UIkit.component('algolia-stats', AlgoliaStats);
  UIkit.component('autocomplete', Autocomplete);
  UIkit.component('checkout-address', CheckoutAddress);
  UIkit.component('consent-button', ConsentButton);
  UIkit.component('comparison', Comparison);
  UIkit.component('deactivate', Deactivate);
  UIkit.component('form-validation', Form);
  UIkit.component('file-upload', FileUpload);
  UIkit.component('chec', FileUpload);
};

// Load polyfills first, then start the application
Promise.all(polyfills).then(app);
