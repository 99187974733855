import { configure } from 'instantsearch.js/es/widgets';
import { assign, isArray } from 'uikit/src/js/util';
import { history } from 'instantsearch.js/es/lib/routers';
import SearchStore from '../../search-store';

const routing = {
  router: history({
    cleanUrlOnDispose: false,
  }),
};

export default {
  props: {
    applicationId: String,
    apiKey: String,
    indexName: String,
    locale: String,
    filter: String,
    searchableAttributes: 'list',
  },

  data: {
    filter: null,
    searchableAttributes: null,
  },

  connected() {
    const searchStore = new SearchStore(
      this.applicationId,
      this.apiKey,
      this.indexName,
      this.locale,
      routing,
    );

    searchStore.initialize()
      .then((store) => {
        let searchParameters = {};

        if (this.filter !== null) {
          searchParameters = assign(searchParameters, {
            filters: this.filter,
          });
        }

        if (isArray(this.searchableAttributes)) {
          searchParameters = assign(searchParameters, {
            restrictSearchableAttributes: this.searchableAttributes,
          });
        }

        store.addWidget(configure(searchParameters));
      });
  },
};
