const hexToRgba = (hex) => {
  const hexCode = hex.replace(/#/, '');
  return [
    parseInt(hexCode.substring(0, 2), 16),
    parseInt(hexCode.substring(2, 4), 16),
    parseInt(hexCode.substring(4, 6), 16),
    parseInt(hexCode.substring(6, 8), 16),
  ];
};

const parseHex = (hex) => {
  let parsedHex = hex.replace('#', ''); // Remove the number sign

  if (parsedHex.length === 3) {
    // If hex is 3 digits, convert it to 6 digits
    parsedHex = parsedHex
      .split('')
      .map((digit) => digit + digit)
      .join('');
  }

  return `#${parsedHex}`;
};

const isHexCode = (value) => /^#([0-9A-F]{3,4}){1,2}$/i.test(value);

export function parseItems(items, separator) {
  const parsedItems = [];

  for (let i = 0, l = items.length; i < l; i += 1) {
    const item = items[i];

    if (!item.parsed) {
      item.parsed = true;

      const itemLabel = item.label;
      const separatorIndex = itemLabel.indexOf(separator);

      // If separator is not present, skip
      if (separatorIndex === -1) {
        throw new Error(
          `[ColorRefinementList] Unable to find 'separator' ('${separator}') in color value, expected format: 'black${separator}#000000', received: '${itemLabel}'.`,
        );
      }

      // Split on the first separator only
      const labelParts = [
        itemLabel.slice(0, separatorIndex),
        itemLabel.slice(separatorIndex + separator.length),
      ];

      if (labelParts.length !== 2) {
        throw new Error(
          `[ColorRefinementList] Unable to parse color value, expected format: 'black${separator}#000000', received: '${itemLabel}'.`,
        );
      }

      const [colorLabel, colorCode] = labelParts;
      if (!colorCode) continue; // eslint-disable-line no-continue

      // Detect if it's a URL or a hex code
      if (isHexCode(colorCode)) {
        item.hex = parseHex(colorCode);
        item.rgba = hexToRgba(item.hex);
      } else {
        item.url = colorCode;
      }

      item.label = colorLabel;
    }

    parsedItems.push(item);
  }

  return parsedItems;
}

export function getContrastColor(bgColor) {
  return bgColor[0] * 0.299 + bgColor[1] * 0.587 + bgColor[2] * 0.114 > 186
    ? '#000000'
    : '#ffffff';
}

export function getColorBackground(item) {
  let background = '';

  if (item.hex) {
    background = `background-color: ${item.hex};`;
  }

  if (item.url) {
    background = `background-image: url(${item.url});`;
  }

  return background;
}
