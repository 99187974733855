import { connectClearRefinements } from 'instantsearch.js/es/connectors';
import {
  $,
  append,
  attr,
  fragment,
  noop,
  on,
  removeAttr,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';

export default {
  props: {
    buttonLabel: String,
  },

  data: {
    buttonLabel: 'Clear filters',
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        store.addWidget(this.renderClearRefinements()({ container: this.$el }));
      });
  },

  methods: {
    renderClearRefinements() {
      const render = (renderOptions, isFirstRender) => {
        const {
          hasRefinements,
          refine,
          widgetParams: { container },
        } = renderOptions;

        if (isFirstRender) {
          const button = fragment(`
            <button class="uk-button uk-button-secondary uk-width-1-1">
              ${this.buttonLabel}
            </button>
          `);

          on(button, 'click', refine);

          append(container, button);
        }

        const buttonElement = $('button', container);

        if (hasRefinements) {
          removeAttr(buttonElement, 'disabled');
        } else {
          attr(buttonElement, 'disabled', 'disabled');
        }
      };

      return connectClearRefinements(render, noop);
    },
  },
};
