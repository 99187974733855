import { pagination } from 'instantsearch.js/es/widgets';
import SearchStore from '../../search-store';

export default {
  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        store.addWidget(
          pagination({
            container: this.$el,
            templates: {
              first: '',
              last: '',
              previous: '<span class="uk-icon uk-icon-button" data-ratio=".6" data-uk-icon="ssz-arrow-back"></span>',
              next: '<span class="uk-icon uk-icon-button" data-ratio=".6" data-uk-icon="ssz-arrow-next"></span>',
            },
            cssClasses: {
              list: 'uk-pagination uk-flex-middle uk-flex-center uk-margin-remove',
              link: 'uk-link-ignore-base',
              selectedItem: 'uk-active',
              disabledItem: 'uk-disabled',
            },
          }),
        );
      });
  },
};
