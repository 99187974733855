import {
  checkRendering,
  createDocumentationMessageGenerator,
  isFiniteNumber,
  find,
  noop,
} from 'instantsearch.js/es/lib/utils';

const withUsage = createDocumentationMessageGenerator(
  { name: 'range-input', connector: true },
  { name: 'range-slider', connector: true },
);

const $$type = 'ais.range';

function toPrecision({ min, max, precision }) {
  const pow = 10 ** precision;

  return {
    min: min ? Math.floor(min * pow) / pow : min,
    max: max ? Math.ceil(max * pow) / pow : max,
  };
}

/**
 * **Range** connector provides the logic to create custom widget that will let
 * the user refine results using a numeric range.
 *
 * This connector provides a `refine()` function that accepts bounds. It will also provide
 * information about the min and max bounds for the current result set.
 */
const connectRange = function connectRange(renderFn, unmountFn = noop) {
  checkRendering(renderFn, withUsage());

  return (widgetParams) => {
    const {
      attribute = '',
      min: minBound,
      max: maxBound,
      precision = 0,
    } = widgetParams || {};

    console.log(widgetParams);

    if (!attribute) {
      throw new Error(withUsage('The `attribute` option is required.'));
    }

    if (isFiniteNumber(minBound) && isFiniteNumber(maxBound) && minBound > maxBound) {
      throw new Error(withUsage("The `max` option can't be lower than `min`."));
    }

    const formatToNumber = (v) => Number(Number(v).toFixed(precision));

    const rangeFormatter = {
      from: (v) => v.toLocaleString(),
      to: (v) => formatToNumber(v).toLocaleString(),
    };

    const getRefinedState = (
      helper,
      currentRange,
      nextMin,
      nextMax,
    ) => {
      let resolvedState = helper.state;
      const { min: currentRangeMin, max: currentRangeMax } = currentRange;

      const [min] = resolvedState.getNumericRefinement(attribute, '>=') || [];
      const [max] = resolvedState.getNumericRefinement(attribute, '<=') || [];

      const isResetMin = nextMin === undefined || nextMin === '';
      const isResetMax = nextMax === undefined || nextMax === '';

      const { min: nextMinAsNumber, max: nextMaxAsNumber } = toPrecision({
        min: !isResetMin ? parseFloat(nextMin) : undefined,
        max: !isResetMax ? parseFloat(nextMax) : undefined,
        precision,
      });

      let newNextMin;
      if (!isFiniteNumber(minBound) && currentRangeMin === nextMinAsNumber) {
        newNextMin = undefined;
      } else if (isFiniteNumber(minBound) && isResetMin) {
        newNextMin = minBound;
      } else {
        newNextMin = nextMinAsNumber;
      }

      let newNextMax;
      if (!isFiniteNumber(maxBound) && currentRangeMax === nextMaxAsNumber) {
        newNextMax = undefined;
      } else if (isFiniteNumber(maxBound) && isResetMax) {
        newNextMax = maxBound;
      } else {
        newNextMax = nextMaxAsNumber;
      }

      const isResetNewNextMin = newNextMin === undefined;

      const isGreaterThanCurrentRange = isFiniteNumber(currentRangeMin)
        && currentRangeMin <= newNextMin;
      const isMinValid = isResetNewNextMin
        || (isFiniteNumber(newNextMin)
          && (!isFiniteNumber(currentRangeMin) || isGreaterThanCurrentRange));

      const isResetNewNextMax = newNextMax === undefined;
      const isLowerThanRange = isFiniteNumber(newNextMax) && currentRangeMax >= newNextMax;
      const isMaxValid = isResetNewNextMax
        || (isFiniteNumber(newNextMax)
          && (!isFiniteNumber(currentRangeMax) || isLowerThanRange));

      const hasMinChange = min !== newNextMin;
      const hasMaxChange = max !== newNextMax;

      if ((hasMinChange || hasMaxChange) && isMinValid && isMaxValid) {
        resolvedState = resolvedState.removeNumericRefinement(attribute);

        if (isFiniteNumber(newNextMin)) {
          resolvedState = resolvedState.addNumericRefinement(
            attribute,
            '>=',
            newNextMin,
          );
        }

        if (isFiniteNumber(newNextMax)) {
          resolvedState = resolvedState.addNumericRefinement(
            attribute,
            '<=',
            newNextMax,
          );
        }

        return resolvedState.resetPage();
      }

      return null;
    };

    const createSendEvent = (instantSearchInstance) => (...args) => {
      if (args.length === 1) {
        instantSearchInstance.sendEventToInsights(args[0]);
      }
    };

    // eslint-disable-next-line no-underscore-dangle
    function _getCurrentRange(stats) {
      let min;
      if (isFiniteNumber(minBound)) {
        min = minBound;
      } else if (isFiniteNumber(stats.min)) {
        min = stats.min;
      } else {
        min = 0;
      }

      let max;
      if (isFiniteNumber(maxBound)) {
        max = maxBound;
      } else if (isFiniteNumber(stats.max)) {
        max = stats.max;
      } else {
        max = 0;
      }

      return toPrecision({ min, max, precision });
    }

    // eslint-disable-next-line no-underscore-dangle
    function _getCurrentRefinement(helper) {
      const [minValue] = helper.getNumericRefinement(attribute, '>=') || [];
      const [maxValue] = helper.getNumericRefinement(attribute, '<=') || [];

      const min = isFiniteNumber(minValue) ? minValue : -Infinity;
      const max = isFiniteNumber(maxValue) ? maxValue : Infinity;

      return [min, max];
    }

    // eslint-disable-next-line no-underscore-dangle
    function _refine(helper, currentRange) {
      return ([nextMin, nextMax] = [undefined, undefined]) => {
        const refinedState = getRefinedState(helper, currentRange, nextMin, nextMax);

        if (refinedState) {
          helper.setState(refinedState).search();
        }
      };
    }

    return {
      $$type,

      init(initOptions) {
        renderFn({
          ...this.getWidgetRenderState(initOptions),
          instantSearchInstance: initOptions.instantSearchInstance,
        }, true);
      },

      render(renderOptions) {
        renderFn({
          ...this.getWidgetRenderState(renderOptions),
          instantSearchInstance: renderOptions.instantSearchInstance,
        }, false);
      },

      getRenderState(renderState, renderOptions) {
        return {
          ...renderState,
          range: {
            ...renderState.range,
            [attribute]: this.getWidgetRenderState(renderOptions),
          },
        };
      },

      getWidgetRenderState({ results, helper, instantSearchInstance }) {
        const facetsFromResults = (results && results.disjunctiveFacets) || [];
        const facet = find(facetsFromResults, (facetResult) => facetResult.name === attribute);
        const stats = (facet && facet.stats) || {
          min: undefined,
          max: undefined,
        };

        const currentRange = _getCurrentRange(stats);
        const start = _getCurrentRefinement(helper);
        console.log(currentRange);
        let refine;

        if (!results) {
          // On first render pass an empty range
          // to be able to bypass the validation
          // related to it
          refine = _refine(helper, {
            min: undefined,
            max: undefined,
          });
        } else {
          refine = _refine(helper, currentRange);
        }

        return {
          refine,
          canRefine: currentRange.min !== currentRange.max,
          format: rangeFormatter,
          range: currentRange,
          sendEvent: createSendEvent(instantSearchInstance),
          widgetParams: {
            ...widgetParams,
            precision,
          },
          start,
        };
      },

      dispose({ state }) {
        unmountFn();

        return state
          .removeDisjunctiveFacet(attribute)
          .removeNumericRefinement(attribute);
      },

      getWidgetUiState(uiState, { searchParameters }) {
        const {
          '>=': min = [],
          '<=': max = [],
        } = searchParameters.getNumericRefinements(attribute);

        if (min.length === 0 && max.length === 0) {
          return uiState;
        }

        return {
          ...uiState,
          range: {
            ...uiState.range,
            [attribute]: `${min}:${max}`,
          },
        };
      },

      getWidgetSearchParameters(searchParameters, { uiState }) {
        let widgetSearchParameters = searchParameters
          .addDisjunctiveFacet(attribute)
          .setQueryParameters({
            numericRefinements: {
              ...searchParameters.numericRefinements,
              [attribute]: {},
            },
          });

        if (isFiniteNumber(minBound)) {
          widgetSearchParameters = widgetSearchParameters
            .addNumericRefinement(attribute, '>=', minBound);
        }

        if (isFiniteNumber(maxBound)) {
          widgetSearchParameters = widgetSearchParameters
            .addNumericRefinement(attribute, '<=', maxBound);
        }

        const value = uiState.range && uiState.range[attribute];

        if (!value || value.indexOf(':') === -1) {
          return widgetSearchParameters;
        }

        const [lowerBound, upperBound] = value.split(':').map(parseFloat);

        if (
          isFiniteNumber(lowerBound)
          && (!isFiniteNumber(minBound) || minBound < lowerBound)
        ) {
          widgetSearchParameters = widgetSearchParameters
            .removeNumericRefinement(attribute, '>=');
          widgetSearchParameters = widgetSearchParameters
            .addNumericRefinement(attribute, '>=', lowerBound);
        }

        if (
          isFiniteNumber(upperBound)
          && (!isFiniteNumber(maxBound) || upperBound < maxBound)
        ) {
          widgetSearchParameters = widgetSearchParameters
            .removeNumericRefinement(attribute, '<=');
          widgetSearchParameters = widgetSearchParameters
            .addNumericRefinement(attribute, '<=', upperBound);
        }

        return widgetSearchParameters;
      },
    };
  };
};

export default connectRange;
