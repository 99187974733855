import VariantSelector from './coreshop/variant-selector';

export default {
  props: {
    attributes: Object,
    index: Object,
  },

  data: {
    attributes: null,
    index: null,
  },

  connected() {
    this.variantSelector = new VariantSelector(this.$el, {
      attributes: this.attributes,
      index: this.index,
    });
  },
};
