import {
  $,
  $$,
  addClass,
  append,
  data,
  hasAttr,
  fragment,
} from 'uikit/src/js/util';
import * as FormValidation from '../validation';
import AutoFocus from '../validation/plugins/AutoFocus';
// import phone from '../validation/validators/phone';
// import zipCode from '../validation/validators/zipCode';
import Uikit from '../validation/plugins/Uikit';

export default {
  props: {
    progressCls: String,
  },

  data: {
    progressCls: null,
  },

  computed: {
    form() {
      return this.$el;
    },

    identicalValidatorFields() {
      return $$('[data-fv-identical]', this.$el).map((item) => ({
        element: item,
        field: $(`[name="${item.dataset.fvIdentical___compare}"]`),
      }));
    },

    passwordField() {
      return $('input[type="password"][data-fv-password-strength]', this.$el);
    },

    submitButton() {
      return $('[type="submit"]', this.$el);
    },

    formName() {
      return this.form.name;
    },
  },

  connected() {
    this.fv = FormValidation.formValidation(this.$el, {
      plugins: {
        aria: new FormValidation.plugins.Aria(),
        autoFocus: new AutoFocus(),
        declarative: new FormValidation.plugins.Declarative(),
        defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
        excluded: new FormValidation.plugins.Excluded({
          excluded: (field, element) => (hasAttr(element, 'hidden') || hasAttr(element, 'disabled')),
        }),
        fieldStatus: new FormValidation.plugins.FieldStatus({
          onStatusChanged: (fieldsAreValid) => {
            if (fieldsAreValid) {
              this.submitButton.classList.remove('uk-disabled');
            } else {
              addClass(this.submitButton, 'uk-disabled');
            }
          },
        }),
        submitButton: new FormValidation.plugins.SubmitButton(),
        trigger: new FormValidation.plugins.Trigger(),
        uikit: new Uikit({ messageClass: 'uk-text-danger uk-text-small' }),
      },
    });

    // Update identical validators
    if (this.identicalValidatorFields.length > 0) {
      this.updateIdenticalValidators();
    }

    // Register and initialize password strength plugin
    if (this.passwordField) {
      this.registerPasswordStrengthPlugin();
    }
  },

  methods: {
    registerPasswordStrengthPlugin() {
      const passwordMeter = fragment('<progress class="uk-progress uk-progress-danger" value="0" max="100"></progress>');

      append(this.passwordField.parentNode, passwordMeter);

      import(/* webpackChunkName: 'fv-plugin-password-strength' */ '../validation/plugins/PasswordStrength')
        .then(({ default: PasswordStrength }) => {
          this.fv.registerPlugin('passwordStrength', new PasswordStrength({
            field: this.passwordField.name,
            message: data(this.passwordField, 'fv-password-strength___message'),
            minimalScore: 3,
            onValidated: (valid, message, score) => {
              console.log(score);
              passwordMeter.value = Math.ceil((score / 4) * 100);

              switch (score) {
                case 0:
                case 1:
                  passwordMeter.classList.remove('uk-progress-(warning|success)');
                  addClass(passwordMeter, 'uk-progress-danger');
                  break;
                case 2:
                  passwordMeter.classList.remove('uk-progress-(danger|success)');
                  addClass(passwordMeter, 'uk-progress-warning');
                  break;
                case 3:
                case 4:
                  passwordMeter.classList.remove('uk-progress-(danger|warning)');
                  addClass(passwordMeter, 'uk-progress-success');
                  break;
                default:
                  break;
              }
            },
          }));
        });
    },

    updateIdenticalValidators() {
      this.identicalValidatorFields.forEach(({ element, field }) => {
        this.fv.updateValidatorOption(element.name, 'identical', 'compare', () => field.value);
      });
    },
  },
};
