import { connectRange } from 'instantsearch.js/es/connectors';
import {
  $,
  append,
  fragment,
  html,
  noop,
  on,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';

export default {
  args: 'attribute',

  props: {
    attribute: String,
    priceFromLabel: String,
    priceToLabel: String,
  },

  data: {
    priceFromLabel: 'From',
    priceToLabel: 'To',
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        if (store.state.disjunctiveFacets.includes(this.attribute)) {
          return;
        }

        store.addWidget(
          this.renderRangeInput()({
            container: this.$el,
            attribute: this.attribute,
          }),
        );
      });
  },

  methods: {
    renderRangeInput() {
      const render = (renderOptions, isFirstRender) => {
        const {
          range,
          refine,
          start: [min, max],
          widgetParams: { container },
        } = renderOptions;

        if (isFirstRender) {
          const form = fragment('<form></form>');

          on(form, 'change', ({ currentTarget }) => {
            const rawMinInputValue = parseFloat(currentTarget.elements.min.value);
            const rawMaxInputValue = parseFloat(currentTarget.elements.max.value);

            refine([
              Number.isFinite(rawMinInputValue) ? rawMinInputValue : undefined,
              Number.isFinite(rawMaxInputValue) ? rawMaxInputValue : undefined,
            ]);
          });

          append(container, form);

          return;
        }

        html($('form', container), `
          <div class="uk-grid uk-grid-small uk-child-width-1-2" data-uk-grid>
            <div>
              <div class="uk-form-controls uk-position-relative">
                <span class="uk-form-icon uk-text-meta">${this.priceFromLabel}</span>
                <input
                  class="uk-input uk-margin-remove"
                  type="number"
                  name="min"
                  placeholder="${range.min}"
                  value="${Number.isFinite(min) ? min : ''}"
                >
              </div>
            </div>
            <div>
              <div class="uk-form-controls uk-position-relative">
                <span class="uk-form-icon uk-text-meta">${this.priceToLabel}</span>
                <input
                  class="uk-input uk-margin-remove"
                  type="number"
                  name="max"
                  placeholder="${range.max}"
                  value="${Number.isFinite(max) ? max : ''}"
                >
              </div>
            </div>
          </div>
        `);
      };

      return connectRange(render, noop);
    },
  },
};
