import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { hasOwn } from 'uikit/src/js/util';
import '../../scss/components/autocomplete.scss';

export default {
  props: {
    indexConfigs: Object,
    indexName: String,
    locale: String,
    searchUrl: String,
    filter: String,
    translations: Object,
  },

  data: {
    translations: null,
    searchUrl: '',
  },

  beforeConnect() {
    const searchClient = algoliasearch(
      this.indexConfigs.product.application_id,
      this.indexConfigs.product.public_key,
    );

    this.autocomplete = autocomplete({
      container: this.$el,
      placeholder: this.getTranslation('placeholder'),
      translations: {
        clearButtonTitle: this.getTranslation('clear'),
        detachedCancelButtonText: this.getTranslation('cancel'),
        submitButtonTitle: this.getTranslation('submit'),
      },
      detachedMediaQuery: '',
      openOnFocus: true,
      onSubmit: ({ state }) => {
        console.log(this.searchUrl);
        window.location.href = `${this.searchUrl}?query=${encodeURI(state.query)}`;
      },
      getSources: ({ query }) => {
        if (!query) {
          return [];
        }

        return [
          {
            sourceId: 'category',
            getItemUrl: ({ item }) => item[`${this.getLocalizedAttribute('slug')}`],
            getItems: () => getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: this.indexConfigs.category.name,
                  query,
                  params: {
                    attributesToSnippet: [`${this.getLocalizedAttribute('name')}:10`],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            }),
            templates: {
              header: ({ html }) => html`<div>
                  <span class="uk-text-primary uk-text-small uk-text-bold">${this.getTranslation('category_source_header')}</span>
              </div>`,
              item: ({ item, html, components }) => html`
                  <a href="${item[this.getLocalizedAttribute('slug')]}" class="aa-ItemLink uk-link-ignore-base">
                      <div class="aa-ItemContent">
                          <div class="aa-ItemContentBody">
                              <div class="aa-ItemContentTitle">
                                  <span>${components.Highlight({ hit: item, attribute: this.getLocalizedAttribute('name') })}</span>
                              </div>
                          </div>
                      </div>
                      <div class="aa-ItemActions">
                         
                      </div>
                  </a>
              `,
              noResults: () => this.getTranslation('no_categories'),
            },
          },
          {
            sourceId: 'product',
            getItemUrl: ({ item }) => item[`${this.getLocalizedAttribute('slug')}`],
            getItems: () => getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: this.indexConfigs.product.name,
                  query,
                  params: {
                    hitsPerPage: 10,
                    attributesToSnippet: [`${this.getLocalizedAttribute('name')}:10`],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            }),
            templates: {
              header: ({ html }) => html`<div>
                <span class="uk-text-primary uk-text-small uk-text-bold">${this.getTranslation('product_source_header')}</span>
              </div>`,
              item: ({ item, html, components }) => html`
                <a class="aa-ItemLink uk-link-ignore-base" href="${item[this.getLocalizedAttribute('slug')]}">
                  <div class="aa-ItemContent">
                    <div class="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                        <img src="${item.image.path}" class="uk-blend-multiply" alt="${item[this.getLocalizedAttribute('name')]}" width="40" height="40" />
                    </div>
                    <div class="aa-ItemContentBody">
                      <div class="aa-ItemContentTitle">
                        <span>${components.Highlight({ hit: item, attribute: this.getLocalizedAttribute('name') })}</span>
                      </div>
                      <div>
                          <span>${components.Highlight({ hit: item, attribute: this.getLocalizedAttribute('brand') })}</span>
                      </div>
                    </div>
                  </div>
                  <div class="aa-ItemActions">
                  </div>
                </a>
              `,
              noResults: () => this.getTranslation('no_results'),
            },
          },
        ];
      },
      navigator: {
        navigate({ itemUrl }) {
          window.location.assign(itemUrl);
        },
        navigateNewTab({ itemUrl }) {
          const windowReference = window.open(itemUrl, '_blank', 'noopener');

          if (windowReference) {
            windowReference.focus();
          }
        },
        navigateNewWindow({ itemUrl }) {
          window.open(itemUrl, '_blank', 'noopener');
        },
      },
    });
  },

  methods: {
    getLocalizedAttribute(attribute) {
      return `${attribute}_${this.locale}`;
    },

    getTranslation(id) {
      if (!hasOwn(this.translations, id)) {
        return id;
      }

      return this.translations[id];
    },
  },
};
