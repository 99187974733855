import { connectHierarchicalMenu } from 'instantsearch.js/es/connectors';
import {
  $,
  $$,
  append,
  fragment,
  html,
  noop,
  on,
} from 'uikit/src/js/util';
import SearchStore from '../../search-store';

export default {
  args: 'attributes',

  props: {
    attributes: 'list',
    noItemsLabel: String,
    rootPath: String,
    showLessLabel: String,
    showMoreLabel: String,
  },

  data: {
    noItemsLabel: 'No filter items available',
    showLessLabel: 'Show less',
    showMoreLabel: 'Show more',
  },

  connected() {
    SearchStore.instance.initialize()
      .then((store) => {
        if (store.state.hierarchicalFacets.some(({ name }) => name === this.attributes[0])) {
          return;
        }

        store.addWidget(
          this.renderHierarchicalMenu()({
            container: this.$el,
            attributes: this.attributes,
            rootPath: this.rootPath,
            sortBy: ['name:asc'],
            showMore: true,
            showMoreLimit: 20,
            showParentLevel: false,
          }),
        );
      });
  },

  methods: {
    renderHierarchicalMenu() {
      const renderList = ({ items, createURL }) => `
        <ul class="uk-list uk-list-collapse uk-margin-remove">
          ${items.map((item) => `
            <li>
              <a href="${createURL(item.value)}"
                 class="uk-link-ignore-base ${(item.isRefined || Array.isArray(Object.values(SearchStore.instance.state.hierarchicalFacetsRefinements).find((attribute) => attribute[0] === item.value))) ? 'uk-text-bold' : ''}"
                 data-value="${item.value}">
                ${item.label} <span class="uk-badge uk-badge-muted">${item.count}</span>
              </a>
              ${item.data ? renderList({ items: item.data, createURL }) : ''}
            </li>
          `).join('')}
        </ul>
      `;

      const render = (renderOptions, isFirstRender) => {
        const {
          canToggleShowMore,
          createURL,
          items,
          isShowingMore,
          refine,
          toggleShowMore,
          widgetParams: { container },
        } = renderOptions;

        if (isFirstRender) {
          const wrapper = fragment('<div></div>');
          const button = fragment(`<button class="uk-button uk-button-default uk-button-small uk-margin-top">${this.showMoreLabel}</button>`);

          on(button, 'click', () => {
            toggleShowMore();
          });

          append(container, wrapper);
          append(container, button);
        }

        if (items.length > 0) {
          const children = renderList({ items, createURL });

          html($('div', container), children);

          on($$('a', container), 'click', (event) => {
            event.preventDefault();
            refine(event.currentTarget.dataset.value);
          });
        } else {
          html($('div', container), `<p>${this.noItemsLabel}</p>`);
        }

        const button = $('button', container);

        button.hidden = !canToggleShowMore;
        button.textContent = isShowingMore ? this.showLessLabel : this.showMoreLabel;
      };

      return connectHierarchicalMenu(render, noop);
    },
  },
};
