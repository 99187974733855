import { $, $$, removeClass } from 'uikit/src/js/util';

export default {
  data: {
    btnSel: '.cc-tag-activate-btn',
  },
  computed: {
    btn() {
      return $(this.btnSel, this.$el);
    },
    wrapperDivs() {
      return $$('.webcare-youtube');
    },
    homeSliderDivs() {
      return $$('.tm-header-home-object-wrapper > div.uk-position-cover.uk-position-z-index');
    },
  },
  events: [
    {
      name: 'click',

      el() {
        return this.btn;
      },

      handler() {
        if (this.wrapperDivs) {
          this.wrapperDivs.forEach((item) => {
            removeClass(item, 'uk-position-center');
          });
        }

        if (this.homeSliderDivs) {
          this.homeSliderDivs.forEach((item) => {
            removeClass(item, 'uk-position-z-index');
          });
        }
      },
    },
  ],
};
